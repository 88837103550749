import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components";
import config from "../../config"
import Logo from "../components/Logo"
import Button from "../components/button/button"


const Footer = ({siteTitle}) => (
  	<FooterContainer>

  		<FooterCol>
  			<Logo />

  			<SocialList>
  				<li><Link to={config.facebook}><i className="fab fa-facebook-square"></i></Link></li>
  				<li><Link to={config.instagram}><i className="fab fa-instagram-square"></i></Link></li>
  			</SocialList>	 

  			<p>&copy; {new Date().getFullYear()} - {siteTitle} <span className="th">Website by <a href="https://www.thowardmedia.co.uk">ThowardMedia</a></span></p>
  		</FooterCol>

  		<FooterCol className="links">
  			<h2>Links</h2>
  			<FooterNav>
  				<li><Link to="/services/brickwork">Brickwork & pointing</Link></li>
  				<li><Link to="/services/patios">Patios</Link></li>
  				<li><Link to="/services/domestic">Home extensions & porches</Link></li>
  				<li><Link to="/gallery">Gallery</Link></li>
				  <li><Link to="/contact">Contact</Link></li>
  			</FooterNav>
  		</FooterCol>

  		<FooterCol className="quote">
  			<h2>Get a free quote today</h2>
  			<p>Have you got a project in mind? <br/> Why not contact us today for a free no obligation quotation.</p>
  			 <Button className="quote-btn" text="Get a quote" link="/contact" />
  		</FooterCol>

  		
	</FooterContainer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

const FooterContainer = styled.footer`
  padding: 60px;
  color: #fff;
	background: ${config.secondaryColor};
	display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

	h2 {
		font-size: 22px;
	} 

  .quote-btn {
    &:hover {
      border: 4px solid ${config.primaryColor};
    } 
  }
`

const FooterCol = styled.div`
	display: flex;
	flex-direction: column;

  p {
    margin: 0;
  }

  &.links {
    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: ${config.primaryColor};
      }
    }
  }

  &.quote,
  &.links {
    margin-top: 20px;
  }

	&.quote {
		a {
			max-width: 200px;
		}
	}

  .th {
    display: block;
    font-size: 15px;

    a {
      color: ${config.primaryColor};
      text-decoration: none;
    }
  }
`

const SocialList = styled.ul`
	  margin: 15px;
    list-style: none;
    display: flex;
    font-size: 28px;

    li {

    	&:first-child {
    		margin-right: 10px;
    	}

    	a {
    		color: #fff;

    		&:hover {
    			color: ${config.primaryColor};
    		}
    	}
    }
`

const FooterNav = styled.ul`
	list-style: none;
	margin: 0;

	li {
		margin: 0;
	}
`

export default Footer