import PropTypes from "prop-types"
import React, { Component } from 'react'
import styled from "styled-components"

import NavBar from "../components/NavBar"
import config from "../../config"


export default class Header extends Component {

  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (window.pageYOffset > 50) {
      document.getElementById("header").classList.add("sticky");
    } else {
      document.getElementById("header").classList.remove("sticky");
    }
  };
 

render() {

const SiteHeader = styled.header`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    &.sticky {
    	background: ${config.secondaryColor};
    }
` 

    
    return (
    <SiteHeader id="header">
        <link rel="dns-prefetch" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css" as="style" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css" media="all" onLoad={(e)=> { this.media='all' }}/>
  	    <NavBar />
  	</SiteHeader>
     
    )
  }
}



Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};



