import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import config from "../../../config"


const Button = ({link, text, className}) => {
  return (
    <ButtonLink className={className} to={link}>{text}</ButtonLink> 
  );
};


Button.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
}

Button.defaultProps = {
  text: ``,
  link: ``,
  className: '',
}


const ButtonLink = styled(Link) `
	color: #ffffff;
	text-decoration: none;
	background: ${config.primaryColor};
	cursor: pointer;
	padding: 10px 30px;
	display: inline-block;
	margin: 15px 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	outline: none;
	position: relative;
	font-size: 16px;
	border: 4px solid ${config.primaryColor};

    &:hover {
    	background: ${config.secondaryColor};
    	color: ${config.primaryColor};
		border: 4px solid ${config.secondaryColor};
    }
` 
 

export default Button