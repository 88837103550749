import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import config from "../../../config"


const Button = ({link, text}) => {
  return (
  	<ButtonLink href={link} ><i className="fas fa-phone-alt"></i>Call <span>now</span></ButtonLink>
  );
};


Button.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

Button.defaultProps = {
  text: ``,
  link: ``,
}


const ButtonLink = styled.a `
	color: #ffffff;
	text-decoration: none;
	cursor: pointer;
	padding: 10px 20px;
	display: inline-block;
	margin: 20px 0 20px 30px;
	text-transform: uppercase;
	letter-spacing: 1px;
	outline: none;
	position: relative;
	line-height: 20px;
	font-size: 14px;
	background: ${config.primaryColor};
	border: 2px solid ${config.primaryColor};

	i {
		margin-right: 5px;
	}

    &:hover {
    	color: ${config.secondaryColor};
		border: 2px solid ${config.primaryColor};
    }

    @media(max-width: 768px) {
    	margin-left: 0;
    }

    @media(min-width: 768px) and (max-width: 840px) {
    	margin: 0 0 0 10px;
    	padding: 8px;

    	span {
    		display: none;
    	}
    }
` 
 

export default Button